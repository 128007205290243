@import "../../shared/_components";
@import "../../_variables";

$category-padding: 5px;
$category-item-height: ($product-category-height + 2 * $category-padding);

#product-category{
  background-color: #eee;

  .title{
    text-transform: uppercase;
    font-size: 14px;
  }

  .category-container{
    display: flex;
    flex-wrap: wrap;
    margin-left: -$category-padding;
    margin-right: -$category-padding;
    overflow: hidden;
    max-height: $category-item-height * $number-of-category-lines;

    -webkit-transition: max-height .3s;
    transition: max-height .3s;

    .category-item-link{
      height: $category-item-height;
      width: 50%;
      padding: 5px;

      overflow: hidden;
      @include proper-block();
      @include reset-link();

      .category-item{
        height: 100%;
        width: 100%;
        background-color: #fff;
        display: flex;

        .icon{
          width: 40px;
          height: 100%;
          display: flex;
          flex: 0 0 40px;
          padding: 5px;
          justify-content: center;
          align-items: center;
        }

        .name{
          flex-grow: 1;
          height: 100%;
          padding: 5px 5px 5px 0;
          display: flex;
          align-items: center;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
        }

      }
    }
  }

  .accordion-button-container{
    margin-top: 5px;
    width: 100%;
    .accordion-button{
      text-align: center;
      width: 100%;
      padding: 10px 5px;
      background-color: $nav-bg-color;
      color: #fff;
      .oi{
        font-size: 10px;
        margin-right: 5px;
      }
    }
  }

}