@import "../../variables";
@import "../../shared/components";

#price-table-link {
  .content{
    .price-table-link-button{
      @include proper-flex();
      justify-content: space-between;
      padding: 30px 15px;
      border-radius: 3px;
      border: 1px solid #ccc;
      text-decoration: none;
      background-image: url('/images/bgs/hkshphonelinkbg.png');
      background-repeat: no-repeat no-repeat;
      background-position: right center;
      background-size: cover;
      color: #fff;

      .left-side{
        font-weight: 700;
        font-size: 20px;
        text-shadow: #333 0 0 10px;
        .text.sub{
          font-size: 16px;
        }
      }

      .right-side{
        @include flex-static(30px);
        text-align: right;
        text-shadow: #333 0 0 10px;
        display: flex;
        align-items: center;
        //color: #555;
      }
    }
  }
}