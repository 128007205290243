@import "../../_variables";
@import "../../shared/_components";

.product-item-container{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  margin-bottom: 5px;
}

$max-product-item-height: $product-picture-height + 5px + $product-name-height + $product-price-height;

.product-item{
  width: 50%;
  padding: 5px;
  @include reset-link();
  @include proper-block();

  .product-item-content {
    display: block;
    border: 1px solid #ccc;
    padding: 5px;

    .picture {
      height: $product-picture-height;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }
    .name{
      overflow: hidden;
      height: $product-name-height;
      color: $product-name-color;
    }
    .price{
      color: $normal-list-price-color;
      font-weight: 700;
      font-size: 18px;
      height: $product-price-height;

      &.discounted{
        color: $discount-price-color;
      }

      .currency{
        font-size: 14px;
      }

      .original-price{
        font-size: 16px;
        color: $discounted-list-price-color;
        text-decoration: line-through;
        .currency{
          font-size: 12px;
        }
        .amount{

        }
      }
    }
  }

  .product-item-progress{
    display: none;
  }

  &.product-item-loading{
    .product-item-content{
      display: none;
    }

    .product-item-progress{
      display: block;
      border: 1px solid #ccc;
      padding: 5px;
      position: relative;

      .animated-background{
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: placeHolderShimmer;
        background: #4988a3; /* Old browsers */
        background: -moz-linear-gradient(left, #4988a3 25%, #529fbf 50%, #4988a3 75%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #4988a3 25%, #529fbf 50%, #4988a3 75%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #4988a3 25%, #529fbf 50%, #4988a3 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        background-size: 1000px $max-product-item-height;
        opacity: 0.75;
        height: $max-product-item-height;
      }

      .masker{
        background-color: #fff;
        position: absolute;
        &.mask-picture-separator{
          width: 100%;
          top: $product-picture-height + 5px;
          left: 0;
          height: 8px;
        }

        &.mask-name-line-sep{
          width: 100%;
          left: 0;
          top: $product-picture-height + 5px + ($product-name-height / 2);
          height: 3px;
        }

        &.mask-name-end{
          width: 20%;
          right: 5px;
          top: $product-picture-height + 5px + ($product-name-height / 2) + 3px;
          height: ($product-name-height / 2) - 3px;
        }

        &.mask-price-separator{
          width: 100%;
          left: 0;
          top: $product-picture-height + 5px + $product-name-height - 3px;
          height: 10px;
        }

        &.mask-price-end{
          width: 40%;
          bottom: 5px;
          right: 5px;
          height: $product-price-height;
        }
      }
    }


    @keyframes placeHolderShimmer{
      0%{
        background-position: -500px 0
      }
      100%{
        background-position: 500px 0
      }
    }
  }
}

.product-item-info{
  display: flex;
  flex-direction: column;
  .load-more-button{
    width: 100%;
    text-align: center;
    padding: 10px 5px;
    .oi{
      font-size: 12px;
    }
  }

  .product-item-info{
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;

    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .description{
      margin-bottom: 5px;
    }

    .hk-sq-btn{
      background-color: #fff;
    }

    &.error{
      background-color: #ffbab3;
      border-color: darken(#ffbab3, 50%);
      .oi{
        color: #ad0000;
      }
    }
  }
}