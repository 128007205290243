@import "../../shared/_components";
@import "../../_variables";

#contact-detail{
  display: flex;
  flex-direction: column;
  color: #333;

  .title{
    text-transform: uppercase;
    font-size: 14px;
  }
  .contact-group, .contact-row{
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;

    .icon{
      margin-right: 10px;

      width: 30px;
      height: 30px;
      font-size: 20px;

      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

      [class^="im-ent-"], [class*=" im-ent-"] {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }

      .im-ent-home{
        color: #fca604;
      }

      .im-ent-mobile{
        color: #666;
      }
    }
  }
  .contact-link{
    @include reset-link();
  }
  .contact-row{
    min-height: 30px;

    .provider{
      display: flex;
      align-items: center;
      width: 30px;
      margin-right: 10px;
      flex-shrink: 0;
    }
    .contact{
      display: flex;
      flex-grow: 1;
      justify-content: center;
      flex-direction: column;
      .see-map{
        font-size: 14px;
        color: $default-link-color;
      }
    }
    .action{
      display: flex;
      align-items: center;
      width: 80px;
      flex-shrink: 0;

      .contact-link{
        margin-left: 10px;

        .call, .message{
          border: 1px solid #ddd;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .call{
          color: #5fbb3f;
        }
        .message{
          color: #3963ca;
        }
      }
    }
  }

  .contact-group-data{
    flex-grow: 1;
    .contact-row{
      margin-top: 0;
      margin-bottom: 5px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}