.glide {
  $that: '.glide' !default;


  &__wrapper {}


  &__track {}


  &__slide {}


  &__arrows {
    position: absolute;

    #{$that}--horizontal & {
      top: 50%;
      width: 92%;
      margin-left: 4%;
    }

    #{$that}--vertical & {
      left: 50%;
      height: 88%;
      margin-top: 6%;
    }
  }


  &__arrow {
    position: absolute;
    z-index: 2;
    color: white;
    text-transform: uppercase;
    font: 11px Arial, sans-serif;
    padding: 9px 12px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    transition: border 300ms ease-in-out;

    &:focus { outline: none; }
    &:hover { border-color: white; }

    #{$that}--horizontal & {
      transform: translateY(-50%);

      &.prev { left: 0; }
      &.next { right: 0; }
    }

    #{$that}--vertical & {
      transform: translateX(-50%);

      &.prev { top: 0; }
      &.next { bottom: 0; }
    }
  }


  &__bullets {
    position: absolute;
    z-index: 2;
    list-style: none;

    #{$that}--horizontal & {
      bottom: 10%; left: 0;
      width: 100%;
      height: 12px;
      text-align: center;
    }

    #{$that}--vertical & {
      top: 50%; right: 8%;
      width: 12px;
      height: auto;
      transform: translateY(-50%);
    }

    & > * {
      display: inline-block;
      background-color: rgba(255, 255, 255, 0.5);
      width: 12px;
      height: 12px;
      padding: 0;
      cursor: pointer;
      border-radius: 50%;
      border: 2px solid transparent;
      transition: all 300ms ease-in-out;

      #{$that}--horizontal & {
        margin: 0 5px;
      }

      #{$that}--vertical & {
        vertical-align: middle;
      }

      &.active { background-color: white; }

      &:focus { outline: none; }

      &:hover,
      &:focus {
        border: 2px solid white;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }


  &--slider {

    #{$that}__arrow {
      opacity: 1;
      transition: opacity 150ms ease;

      &.disabled {
        opacity: 0.33;
      }
    }

  }


  &--carousel {}


  &--slideshow {}

  ul, li{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
  }
}

#slider-section{
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;

  .slider-content{
    height: 240px;
    //width: 100%;
    margin: 0 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    font-size: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    a{
      height: 100%;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img{
      max-width:100%;
      max-height:100%;
    }
  }
}