@import "../../shared/_components";
@import "../../_variables";

#exchange-rate{
  padding: 10px;

  .title{
    text-transform: uppercase;
    font-size: 14px;
  }

  a{
    @include reset-link();
  }
  .fx-rate-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .history-link{
    color: $default-link-color;
    &:hover{
      color: $default-link-hover-color;
    }
    .oi{
      font-size: 12px;
      opacity: 0.8;
    }
  }
  .rate{
    padding-top: 5px;
    padding-bottom: 3px;
  }
  .change{
    font-size: 10px;
  }
  .last-update{
    font-size: 12px;
  }
}