@import "../../shared/_components";
@import "../../_variables";

#banner-upper, #banner-lower{
  .banner-container{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    //justify-content: center;
  }
  .banner-item{
    width: 100%;
    img{
      width: 100%;
      display: block;
    }
    margin-bottom: 10px;
  }
  @media screen and (min-width: 720px) {
    .banner-item{
      width: 50%;
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}