@import "../../shared/_components";
@import "../../_variables";

#deal-of-the-day{
  .title{
    text-transform: uppercase;
    font-size: 14px;
  }
  a{
    @include reset-link();
  }
  .promo-item{
    padding: 10px;
    border: 1px solid #ccc;
    margin: 5px 0;
  }
  .promo-content{
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .photo{
      width: 100%;
      img{
        width: 100%;
        max-width: 100%;
      }
    }

    .details{
      width: 100%;

      .currency{
        font-size: 12px;
      }

      .product-name{

      }

      .price{
        font-weight: 700;
        font-size: 18px;
        color: $discount-price-color;
      }

      .original-price{
        font-size: 14px;
        color: $discounted-list-price-color;
        .list-price{
          .amount{
            text-decoration: line-through;
          }
        }
      }
    }
  }

  .other-deals-button-container{
    width: 100%;
    .other-deals-button{
      width: 100%;
      text-align: center;
      padding: 10px 5px;
      .oi{
        font-size: 12px;
      }
    }
  }

  @media screen and (min-width: 500px){
    .promo-content{
      .photo, .details{
        width: 50%;
      }
      .details{
        padding-left: 10px;
        .product-name{
          font-size: 18px;
        }
        .price{
          font-size: 24px;
        }
        .original-price{
          font-size: 16px;
        }
      }
    }
  }
}