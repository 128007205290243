@import "../../_variables";
@import "../../shared/_components";

#latest-products{
  .content{

  }
  .title{
    text-transform: uppercase;
    font-size: 14px;
  }
  .more-latest-button-container{
    width: 100%;
    .more-latest-button{
      width: 100%;
      text-align: center;
      padding: 10px 5px;
      .oi{
        font-size: 12px;
      }
    }
  }
}